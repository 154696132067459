.circle {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  background: #000000aa;
  display: table-cell;
}

.centered-screen {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 100px;
  height: 100px;
  display: table;
  width: 100%; /*Optional*/
  table-layout: fixed; /*Optional*/
}

.centered-table {
  border-style: solid;
  top: 15%;
  left: 5%;
  width: 90%;
  height: 100px;
  display: table;
  table-layout: fixed; /*Optional*/
}

.cell-wrapper {
  display: table-cell;
  float: left;
  width: 50%;
}

.connector-wrapper {
  display: table-cell;
}

.rectangle {
  border-bottom: 5px solid black;
  text-align: center;
  font-weight: bold;
  font-size:larger;
  display: table-cell;
  width: 150px;
}

.slider {
  text-align: center;
  width: 150px;
}

.input-value {
  text-align: left;
  width: 750px;
}