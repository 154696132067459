table, th, td {
  border-style: solid;
  border-width: thin;
  padding: 5px;
}

table {
  -moz-border-radius: 5px !important;
  border-collapse: collapse !important;
  border: none !important;
}
table th,
table td {
  border: none !important;
  color: #2E2D2D;
}
table th:first-child {
  -moz-border-radius: 5px 0 0 0 !important;
}
table th:last-child {
  -moz-border-radius: 0 5px 0 0 !important;
}
table tr:last-child td:first-child {
  -moz-border-radius: 0 0 0 5px !important;
}
table tr:last-child td:last-child {
  -moz-border-radius: 0 0 5px 0 !important;
}

table tr:hover th {
  background-color: #ddd !important
}

table tr:hover td {
  background-color: #ddd !important
}
